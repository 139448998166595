import React, { useCallback, useEffect } from 'react'
import { theme } from '@damen/ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { Container, ModelList, SecondaryHeader } from '@/components/UI'

import { useBreadcrumb } from '@/hooks'
import { TabFamily } from '@/queries/schema.generated'
import { ModalProps } from '@/components/UI/Modal/types'
import VideoHeader from '@/components/UI/VideoHeader'
import { BlokZone } from '@/components/UI/BlokZone'
import Components from '@/components/Components'

const DownloadModal = dynamic(() => import('@/components/Modal/Download'), { ssr: false })

interface Props {
  content: any
  // TODO: explicit validation
  downloadData?: any
  selectedModal?: ModalProps
  handleCloseModal?: any
  setOpenModal?: (props: ModalProps) => void
}

const StyledMotionDiv = styled(motion.div)`
  position: relative;
`

const Wrapper = styled(Container)`
  padding-top: 0;
`

const Presentational: React.FC<React.PropsWithChildren<Props>> = ({
  content,
  downloadData,
  selectedModal,
  setOpenModal,
  handleCloseModal,
}) => {
  const { header, models, overview, enableDirectProductSheetDownload } = content
  const hashId = typeof window !== 'undefined' ? window.location.hash : null

  const breadcrumb = useBreadcrumb(header.breadcrumb?.title, header.breadcrumb?.link, 'white', theme.colors.marineBlack)

  const { query } = useRouter()

  const animateIntoView = (id: string) => {
    if (typeof window !== 'undefined') {
      if (id) {
        const element = document.querySelector(id)
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            })
          }, 500)
        }
      }
    }
  }

  const mapTabToHandle = useCallback((tab: TabFamily) => tab.text.toLowerCase(), [])

  const getTabHandle = useCallback(
    (index: number) => {
      const tabsFamilyComponent = content?.overview?.find((component: any) => component.__typename === 'TabsFamily')
      if (tabsFamilyComponent?.tabs[index])
        return mapTabToHandle(tabsFamilyComponent.tabs[index])

      // Fallback on old implementation where tabs names were defined statically
      return index === 0 ? 'overview' : 'models'
    },
    [content?.overview, mapTabToHandle],
  )

  const queryViewIsSecondTab = () => query.view === getTabHandle(1)

  useEffect(() => {
    animateIntoView(hashId)
  })

  const queryViewIsSecondTabCurrent = queryViewIsSecondTab()

  const enrichedComponents = overview.map((item: any) => {
    if (item.__typename === 'TabsFamily') {
      return {
        ...item,
        label: header.label,
        title: header.title,
      }
    }

    return item
  })

  const filteredModelsComponents = enrichedComponents.filter(
    (component: any) => component.__typename === 'Breadcrumb' || component.__typename === 'TabsFamily',
  )

  return (
    <>
      {header.__typename === 'HeaderVideo' && <VideoHeader content={header} />}
      {header.__typename === 'HeaderSecondaryWithTabs' && (
        <SecondaryHeader
          content={{
            id: header.id,
            header: { ...header, breadcrumb },
          }}
        />
      )}
      <AnimatePresence mode="wait">
        {!queryViewIsSecondTabCurrent && (
          <motion.div
            key="overview"
            animate={{ opacity: 1, transition: { opacity: { duration: 0.5, delay: 0.3 } } }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <BlokZone bloks={enrichedComponents} componentLookup={Components} additionalProps={{ setOpenModal }} />
          </motion.div>
        )}
        {queryViewIsSecondTabCurrent && (
          <StyledMotionDiv
            key="models"
            animate={{ opacity: 1, transition: { opacity: { duration: 0.5, delay: 0.3 } } }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <BlokZone
              bloks={filteredModelsComponents}
              componentLookup={Components}
              additionalProps={{ setOpenModal }}
            />
            <Wrapper background="light">
              <section>
                <ModelList
                  models={models}
                  setOpenModal={setOpenModal}
                  enableDirectProductSheetDownload={enableDirectProductSheetDownload}
                />
              </section>
            </Wrapper>
          </StyledMotionDiv>
        )}
      </AnimatePresence>
      <DownloadModal
        selectedModal={selectedModal?.type === 'download' ? selectedModal : null}
        handleCloseModal={handleCloseModal}
        modalDownload={downloadData}
      />
    </>
  )
}

export default Presentational
