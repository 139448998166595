import React, { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'
import Layout from '@/components/layout'
import ContentPresentational from '@/screens/ContentPage/Presentational'
import FamilyPresentational from '@/screens/Family/Presentational'

import { Screen } from '@/types'
import { GetFamilyPageDocument, GetFamilyPageQuery } from '@/queries/queries/Family/getFamiliyPage.generated'
import { ModalProps } from '@/components/UI/Modal/types'

const Family: React.FC<React.PropsWithChildren<Screen>> = ({ slug }) => {
  const { loading, data } = useQuery<GetFamilyPageQuery>(GetFamilyPageDocument, {
    variables: {
      id: slug,
    },
  })

  const [selectedModal, setModal] = useState<ModalProps>(null)

  const isFamilyComponent = data?.familyPage?.content?.__typename === 'FamilyContent'

  const setOpenModal = useCallback((props: ModalProps) => {
    // eslint-disable-next-line default-case
    switch (props.type) {
      case 'quote':
        setModal(props)
        break
      case 'download':
        setModal(props)
        break
      case 'newsletter':
      case 'contact':
        window.open(props.data.link.url || props.data.link.cachedUrl, '_blank')
        break
    }
  }, [])

  return (
    <Layout.Default
      footerData={data?.footer}
      loading={loading}
      newsletterData={data?.modalNewsletter?.content}
      contactData={data?.modalContact?.content}
      departmentsContactData={data?.modalDepartmentsContact}
      departmentsNewsletterData={data?.modalDepartmentsNewsletter}
      donationData={data?.modalDonation}
      feedbackData={data?.modalFeedback?.content}
      seoData={data?.familyPage?.content?.seo}
      siteData={data?.site}
    >
      {data?.familyPage &&
        (isFamilyComponent ? (
          <FamilyPresentational
            content={data.familyPage.content}
            downloadData={data.modalDownload?.content}
            selectedModal={selectedModal}
            handleCloseModal={() => setModal(null)}
            setOpenModal={setOpenModal}
          />
        ) : (
          <ContentPresentational
            content={data.familyPage.content as any}
            downloadData={data.modalDownload}
            quoteData={data.modalQuote}
            selectedModal={selectedModal}
            handleCloseModal={() => setModal(null)}
            setOpenModal={setOpenModal}
          />
        ))}
    </Layout.Default>
  )
}

export default Family
